<template>
  <div>
    <Header/>
    <b-container
      style="max-width:95%; padding-top:70px;"
    >
      <b-row>
        <b-col>
          <h3>
            撮影予定カレンダー
          </h3>
        </b-col>
        <b-col>
          <select 
            class="form-select "
            id="searchUser"
            v-model="searchUserID"
          >
            <option 
              value=""
            >
              全件
            </option>
            <option 
              v-for="user in usersData" 
              :key="'user' + user.inUserID"
              :value="user.inUserID"
            >
              {{ user.vcUserLName }}
            </option>
          </select>
        </b-col>
        <b-col 
          style="text-align:right;"
        >
          <b-button
            :disabled="!startFlag"
            variant="primary"
            @click="getPrevCalendar"
          >
            前へ
          </b-button>
          <b-button 
            class="ms-2"
            variant="primary"
            @click="getNextCalendar"
          >
            次へ
          </b-button>
        </b-col>
      </b-row>

      <b-overlay
        :show="showLoader"
      >
        <b-table-simple 
          class="table table_sticky " 
          responsive
          bordered
        >
          <b-thead 
            class="table-secondary" 
          >
            <b-tr >
              <b-th 
                colspan="1"
                scope="col" 
                style="width:5%"
                class="text-center"
              >
                
              </b-th>
              <b-th 
                colspan="2"
                scope="col"
                style="width:10%"
                class="text-center"
              >
                未撮影
              </b-th>
              <b-th 
                colspan="2"
                scope="col"
                style="width:10%"
                class="text-center"
              >
                撮影完了
              </b-th>
              <b-th 
                colspan="2"
                scope="col"
                style="width:10%"
                class="text-center"
              >
                確認済み
              </b-th>
              <b-th 
                colspan="2"
                scope="col"
                style="width:10%"
                class="text-center "
              >
                合計
              </b-th>
            </b-tr>
            <b-tr>
              <b-th></b-th>
              <b-th>
                内観
              </b-th>
              <b-th>
                外観
              </b-th>
              <b-th>
                内観
              </b-th>
              <b-th>
                外観
              </b-th>
              <b-th>
                内観
              </b-th>
              <b-th>
                外観
              </b-th>
              <b-th>
                内観
              </b-th>
              <b-th>
                外観
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr 
              v-for="(week, index) in calendar"
              :key="index"
            >
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.datetime == currentDate"
                >
                  <b>
                    {{week.datetime}}
                  </b>
                </span>
                <span 
                  v-else-if="week.datetime == ''"
                >
                  過去撮影分
                </span>
                <span 
                  v-else
                >
                  {{week.datetime}}
                </span>
              </b-td>
              <!-- 内観未撮影 -->
              <b-td 
                class="text-center" 
              >
                <span 
                  v-if="week.notShoot !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':1,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.notShoot }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':1,
                        'date':week.datetime, 
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.notShoot }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  {{ week.notShoot }}
                </span>
              </b-td>
              <!-- 外観未撮影 -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.exteriorNotShot !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':1,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      }}"
                    > 
                      {{ week.exteriorNotShot }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':1,
                        'date':week.datetime,
                        'userID':searchUserID 
                      }}"
                    > 
                      {{ week.exteriorNotShot }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  {{ week.exteriorNotShot }}
                </span>
              </b-td>
              <!-- 内観撮影完了 -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.shootComp !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':2,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.shootComp }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':2,
                        'date':week.datetime,
                        'userID':searchUserID 
                      } }"
                    > 
                      {{ week.shootComp }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  {{ week.shootComp}}
                </span>
              </b-td>
              <!-- 外観撮影完了 -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.exteriorShotComp !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':2,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.exteriorShotComp }}
                    </b-link>
                  </span>
                  <span>
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':2,
                        'date':week.datetime,
                        'userID':searchUserID 
                      } }"
                    > 
                      {{ week.exteriorShotComp }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  {{ week.exteriorShotComp }}
                </span>
              </b-td>
              <!-- 内観確認済み -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.confComp !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':3,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.confComp }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':3,
                        'date':week.datetime,
                        'userID':searchUserID 
                      } }"
                    > 
                      {{ week.confComp }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  {{ week.confComp }}
                </span>
              </b-td>
              <!-- 外観確認済み -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.exteriorConfComp !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':3,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.exteriorConfComp }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':3,
                        'date':week.datetime,
                        'userID':searchUserID 
                      } }"
                    > 
                      {{ week.exteriorConfComp }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  {{ week.exteriorConfComp }}
                </span>
              </b-td>
              <!-- 内観合計 -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.totalCount !== 0"
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':1,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.totalCount }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':1,
                        'progress':[1,2,3],
                        'date':week.datetime,
                        'userID':searchUserID 
                      }}"
                    > 
                      {{ week.totalCount }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  0
                </span>
              </b-td>
              <!-- 外観合計 -->
              <b-td 
                class="text-center"
              >
                <span 
                  v-if="week.exteriorTotalCount !== 0 "
                >
                  <span 
                    v-if="week.datetime == ''"
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':1,
                        'date':calendarTopDay,
                        'overDate':1,
                        'userID':searchUserID
                      } }"
                    > 
                      {{ week.exteriorTotalCount }}
                    </b-link>
                  </span>
                  <span 
                    v-else
                  >
                    <b-link
                      :to="{ name:'progressManagementList',params:{ 
                        'type':0,
                        'progress':[1,2,3],
                        'date':week.datetime,
                        'userID':searchUserID 
                      }}"
                    > 
                      {{ week.exteriorTotalCount }}
                    </b-link>
                  </span>
                </span>
                <span 
                  v-else
                >
                  0
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import { Apimixin } from '../../mixins/api'
import moment from "moment";

export default {
  title: '撮影予定カレンダー',
  components: {
    Header
  },
  mixins:[Apimixin],
  data() {
    return {
      userID:'',
      currentDate: '',
      calendar:[],
      startFlag:false,
      limit:10,
      toDay:'',
      introNotShoot:[],
      introShotComp:[],
      introConfComp:[],
      exteriorNotShot:[],
      exteriorShotComp:[],
      exteriorConfCompCount:[],
      exteriorCalendarLists:[],
      overIntroCount:'',
      overexteriorCount:'',
      searchUserID:'',
      searchUserFlag:false,
      calendarTopDay:''
    };
  },
  created() {
    this.showLoader = true
    this.currentDate = moment().format('YYYY-MM-DD')
    this.createCalendar(this.currentDate)
    this.userID = sessionStorage.getItem('userId')
    //ユーザーデータ取得
    this.getUsers(this.userID)
  },
  mounted() {
    this.searchUserID = this.userID
    this.toDay = moment().format('YYYY-MM-DD 00:00:00')
  },
  methods: {
    //ユーザーでの絞り込み
    searchUser() {
      this.calendar = []
      this.showLoader = true
      this.startFlag = false
      this.currentDate = moment().format('YYYY-MM-DD')
      this.createCalendar(this.currentDate)
      //撮影予定カレンダーリスト取得
      this.getEstateCalendarList(
        moment(this.currentDate).format('YYYY-MM-DD 00:00:00'),
        this.searchUserID
      )
    },
    //内観写真データ取得
    getIntroShootCalendar() {
      if(this.introNotShoot.length !== 0) {
        for(let i = 0;i < this.calendar.length;i++) {
          for(let notShoot of this.introNotShoot) {
            if(this.calendar[i].datetime == notShoot.time) {
              this.calendar[i].notShoot = notShoot.count
              this.calendar[i].totalCount += notShoot.count
            }
            if(this.calendar[i].notShoot === '') {
              this.calendar[i].notShoot = 0
            }
          }
        }
      } else {
        for(let i = 1;i < this.calendar.length;i++) {
          this.calendar[i].notShoot = 0
        }
      }
      if(this.introShotComp.length !== 0) {
        for(let i = 0;i < this.calendar.length;i++) {
          for(let shootComp of this.introShotComp) {
            if(this.calendar[i].datetime == shootComp.time) {
              this.calendar[i].shootComp = shootComp.count
              this.calendar[i].totalCount += shootComp.count
            }
            if(this.calendar[i].shootComp === '') {
              this.calendar[i].shootComp = 0
            }
          }
        }
      } else {
        for(let i = 1;i < this.calendar.length;i++) {
          this.calendar[i].shootComp = 0
        }
      }
      if(this.introConfCompCount.length !== 0) {
        for(let i = 0;i < this.calendar.length;i++) {
          for(let confComp of this.introConfCompCount) {
            if(this.calendar[i].datetime == confComp.time) {
              this.calendar[i].confComp = confComp.count
              this.calendar[i].totalCount += confComp.count
            }
            if(this.calendar[i].confComp === '') {
              this.calendar[i].confComp = 0
            }
          }
        }
      }else {
        for(let i = 1;i < this.calendar.length;i++) {
          this.calendar[i].confComp = 0
        }
      }
      for(let i = 0;i < this.calendar.length;i++) {
        if(this.calendar[i].totalCount == '') {
          this.calendar[i].totalCount = 0
        }
      }
    },
    //外観写真データ取得
    getExteriorShootCalendar() {
      if(this.exteriorNotShot.length !== 0) {
        for(let i = 0;i < this.calendar.length;i++) {
          for(let notShoot of this.exteriorNotShot) {
            if(this.calendar[i].datetime == notShoot.time) {
              this.calendar[i].exteriorNotShot = notShoot.count
              this.calendar[i].exteriorTotalCount += notShoot.count
            }
            if(this.calendar[i].exteriorNotShot === '') {
              this.calendar[i].exteriorNotShot = 0
            }
          }
        }
      } else {
        for(let i = 1;i < this.calendar.length;i++) {
          this.calendar[i].exteriorNotShot = 0
        }
      }
      if(this.exteriorShotComp.length !== 0) {
        for(let i = 0;i < this.calendar.length;i++) {
          for(let shootComp of this.exteriorShotComp) {
            if(this.calendar[i].datetime == shootComp.time) {
              this.calendar[i].exteriorShotComp = shootComp.count
              this.calendar[i].exteriorTotalCount += shootComp.count
            }
            if(this.calendar[i].exteriorShotComp === '') {
              this.calendar[i].exteriorShotComp = 0
            }
          }
        }
      } else {
        for(let i = 1;i < this.calendar.length;i++) {
          this.calendar[i].exteriorShotComp = 0
        }
      }
      if(this.exteriorConfCompCount.length !== 0) {
        for(let i = 0;i < this.calendar.length;i++) {
          for(let confComp of this.exteriorConfCompCount) {
            if(this.calendar[i].datetime == confComp.time) {
              this.calendar[i].exteriorConfComp = confComp.count
              this.calendar[i].exteriorTotalCount += confComp.count
            }
            if(this.calendar[i].exteriorConfComp === '') {
              this.calendar[i].exteriorConfComp = 0
            }
          }
        }
      }else {
        for(let i = 1;i < this.calendar.length;i++) {
          this.calendar[i].exteriorConfComp = 0
        }
      }
      for(let i = 0;i < this.calendar.length;i++) {
        if(this.calendar[i].exteriorTotalCount == '') {
          this.calendar[i].exteriorTotalCount = 0
        }
      }
    },
    
    //カレンダーの生成
    createCalendar(day) {
      this.calendar[0] = ({
        datetime:'',
        notShoot:'',
        exteriorNotShot:'',
        totalCount:0,
        exteriorTotalCount:0,
      })
      this.calendar.push({
        datetime:day,
        notShoot:'',
        shootComp:'',
        confComp:'',
        exteriorNotShot:'',
        exteriorShotComp:'',
        exteriorConfComp:'',
        totalCount:0,
        exteriorTotalCount:0
      })
      for (let i = 0; i < this.limit; i++) {
        this.calendar.push({
          datetime:moment(day).add((i + 1),"day").format('YYYY-MM-DD'),
          notShoot:'',
          shootComp:'',
          confComp:'',
          exteriorNotShot:'',
          exteriorShotComp:'',
          exteriorConfComp:'',
          totalCount:0,
          exteriorTotalCount:0
        });
      }
      this.calendarTopDay = this.calendar[1].datetime
    },

    //次へを押された時
    getNextCalendar() {
      let count = ''
      let day = ''
      this.showLoader = true
      count = this.calendar.length - 1
      day = this.calendar[count].datetime
      this.calendar = []
      this.calendar[0] = ({
        datetime:'',
        notShoot:'',
        exteriorNotShot:'',
        totalCount:0,
        exteriorTotalCount:0
      })
      for (let i = 0; i < (this.limit + 1); i++) {
        this.calendar.push({
          datetime:moment(day).add((i + 1),"day").format('YYYY-MM-DD'),
          notShoot:'',
          shootComp:'',
          confComp:'',
          exteriorNotShot:'',
          exteriorShotComp:'',
          exteriorConfComp:'',
          totalCount:0,
          exteriorTotalCount:0
        });
      }
      this.startFlag = true
      for(let i = 0;i < this.calendar.length;i++) {
        if(this.calendar[i].datetime === this.currentDate ) {
          this.startFlag = false
        }
      }
      let startDay = moment(day).format('YYYY-MM-DD 00:00:00')
      this.calendarTopDay = this.calendar[1].datetime
      if(this.searchUserFlag) {
        this.getEstateCalendarList(startDay,this.searchUserID)
      } else {
        this.getEstateCalendarList(startDay,this.searchUserID)
      }
    },
    //前へを押されたとき
    getPrevCalendar() {
      let day = ''
      this.showLoader = true
      day = this.calendar[1].datetime
      this.calendar = []
      for (let i = 0; i < (this.limit + 1); i++) {
        this.calendar.push({
          datetime:moment(day).add(-(i + 1),"day").format('YYYY-MM-DD'),
          notShoot:'',
          shootComp:'',
          confComp:'',
          exteriorNotShot:'',
          exteriorShotComp:'',
          exteriorConfComp:'',
          totalCount:0,
          exteriorTotalCount:0
        });
        
      }
      this.calendar.sort((a, b) => a.datetime > b.datetime ? 1 : -1);
      this.calendar.unshift({
        datetime:'',
        notShoot:'',
        totalCount:0,
        exteriorTotalCount:0
      })
      this.startFlag = true
      for(let i = 0;i < this.calendar.length;i++) {
        if(this.calendar[i].datetime === this.currentDate ) {
          this.startFlag = false
        }
      }
      let startDay = moment(day).add(-(this.limit + 1),"day").format('YYYY-MM-DD 00:00:00')
      this.calendarTopDay = this.calendar[1].datetime
      if(this.searchUserFlag) {
        this.getEstateCalendarList(startDay,this.searchUserID)
      } else {
        this.getEstateCalendarList(startDay,this.searchUserID)
      }
    },
  },
  watch:{
    'searchUserID':function() {
      this.searchUser()
    }
  }
}
</script>

<style scoped>
.table_sticky {
  display: block;
  overflow-y: scroll;
  height: calc(78vh);
}
thead  {
  position: sticky;
  top: 0;
  z-index: 1;
}
th {
  text-align:center;
}
td {
  text-align:center;
}
</style>

